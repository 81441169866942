enum PosthogFlags {
  RecordWebSession = 'record-web-session',
  SearchOutsideServiceAreas = 'search-outside-service-areas',
  EnableRecentSearches = 'enable-recent-searches',
  SegmentedChat = 'segmented-chat',
  CarPickerV2 = 'car-picker-v2',
  GreatDealBadge = 'great-deal-badge',
  NoCoverageModals = 'no-coverage-modals',
  TotalPriceCarPicker = 'total-price-car-picker',
  StrikeThroughPrice = 'strike-through-price',
  StickyScrollBarStrapi = 'sticky-scrollbar-strapi',
  StickyScrollBarCheckout = 'sticky-scrollbar-checkout',
}

export enum SegmentedChatFlagValues {
  segmentedChatEnabled = 'segmented-chat-enabled',
  control = 'control',
}

export enum NoCoverageModalsFlagGroups {
  enabled = 'treatment',
  disabled = 'control',
}

export default PosthogFlags;
