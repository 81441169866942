import * as snippet from '@segment/snippet';
import { Me } from '@/types/me';
import { GA4SessionInfo, BookingOrderAnalyticsMetadata } from './types';
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from '@/config/global-constants';
import { PostHog, posthog } from 'posthog-js';
import {
  BLOCKED_POSTHOG_EVENTS,
  AnalyticsEventsType,
} from '@/config/analytics-events/analytics-events';

export const renderSnippet = () => {
  const opts = {
    host: 'cdn-segment.drivekyte.com',
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_KEY as string,
    page: false,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
};
export const baseTrackEvent = (
  eventName: AnalyticsEventsType,
  eventDetails?: any,
) =>
  new Promise<void>((resolve) => {
    global.analytics.track(eventName, eventDetails, () => {
      if (!BLOCKED_POSTHOG_EVENTS.includes(eventName)) {
        posthog.capture(eventName, eventDetails);
      }
      resolve();
    });
  });

export const baseTrackPage = (pageName: string, eventDetails?: any) => {
  global.analytics.page(pageName, {
    ...eventDetails,
  });
  posthog.capture('$pageview', { $current_url: pageName, ...eventDetails });
};

export const identify = (user: Me, callback?: () => void) => {
  global.analytics.identify(user.uuid, undefined, undefined, callback);
  posthog.identify(user.uuid);
};

export const getAnonymousId = () => {
  return typeof global.analytics?.user === 'function'
    ? global.analytics?.user()?.anonymousId()
    : '';
};

const getGtagValue = (
  field: string,
  timeoutMs = 5000,
): Promise<string | null> => {
  return new Promise<string | null>((resolve) => {
    const timer = setTimeout(() => {
      console.warn('gtag is not responding');
      resolve(null);
    }, timeoutMs);

    if (typeof window.gtag === 'function') {
      try {
        window.gtag(
          'get',
          GOOGLE_ANALYTICS_MEASUREMENT_ID,
          field,
          (value: string | null) => {
            clearTimeout(timer);
            resolve(value);
          },
        );
      } catch (error) {
        clearTimeout(timer);
        resolve(null);
      }
    } else {
      clearTimeout(timer);
      resolve(null);
    }
  });
};

const getGA4SessionInfo = async (): Promise<GA4SessionInfo | null> => {
  if (typeof window.gtag !== 'function' || !GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    return null;
  }

  const [sessionId, sessionNumber, clientId] = await Promise.all([
    getGtagValue('session_id'),
    getGtagValue('session_number'),
    getGtagValue('client_id'),
  ]);

  if (!sessionId && !sessionNumber && !clientId) {
    return null;
  }
  return {
    client_id: clientId ?? '',
    session_id: sessionId ?? '',
    session_number: sessionNumber?.toString() ?? '',
  };
};

export const getBookingOrderAnalyticsMetadata = async (): Promise<
  BookingOrderAnalyticsMetadata | undefined
> => {
  const googleAnalyticsSessionInfo = await getGA4SessionInfo();

  if (!googleAnalyticsSessionInfo) {
    return undefined;
  }

  return {
    ga_session_info: googleAnalyticsSessionInfo,
  };
};

export const aliasWithSegment = (
  posthogClient: PostHog,
  retries: number = 5,
) => {
  if (retries <= 0) return;

  const segmentAnonymousId = getAnonymousId();
  if (segmentAnonymousId) {
    posthogClient.alias(segmentAnonymousId);
  } else {
    setTimeout(() => aliasWithSegment(posthogClient, retries - 1), 1000);
  }
};
