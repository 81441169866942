import { useInitBranch } from '@/config/branch';
import {
  IS_PRODUCTION,
  REACT_QUERY_RETRY_LIMIT,
  SEARCH_CONFIG_MAPPER,
  POSTHOG_API_KEY,
  POSTHOG_API_HOST,
  IS_BROWSER,
} from '@/config/global-constants';
import '@/config/translations';
import useEnableAuth0Store from '@/hooks/use-enable-auth0-store';
import useInitIntercomTracking from '@/hooks/use-init-intercom-tracking/use-init-intercom-tracking';
import useInitSentry from '@/hooks/use-init-sentry';
import { renderSnippet, aliasWithSegment } from '@/utils/analytics';
import {
  NotificationProvider,
  ThemeBootstrap,
  themes,
} from '@drivekyte/web-components';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { QueryClient, QueryClientProvider } from 'react-query';
import styled, { css } from 'styled-components';
import './fonts.css';
import './css-override.css';
import HydrationZustandProvider from '@/utils/hydration-zustand-provider/hydration-zustand';
import dynamic from 'next/dynamic';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserProvider from '@/components/user-provider';
import { KyteThemeProvider } from '@drivekyte/ui';
import posthog, { PostHog } from 'posthog-js';
import PosthogFlags from '@/types/posthog-flags';
import { NavBarData } from '@/components/cms/types';

const Footer = dynamic(() => import('@/components/footer'), { ssr: false });
const Nav = dynamic(() => import('@/components/nav'));
const IntercomProvider = dynamic(
  () =>
    import('@/config/intercom/intercom').then((mod) => mod.IntercomProvider),
  { ssr: false },
);

const ThemeProvider = dynamic(() =>
  import('styled-components').then((mod) => mod.ThemeProvider),
);

const PostHogProvider = dynamic(() =>
  import('posthog-js/react').then((mod) => mod.PostHogProvider),
);

const OtpModal = dynamic(() => import('@/components/otp-modal/otp-modal'), {
  ssr: false,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: REACT_QUERY_RETRY_LIMIT,
    },
  },
});

if (IS_BROWSER) {
  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_API_HOST,
    capture_pageview: false,
    autocapture: false,
    disable_compression: !IS_PRODUCTION,
    disable_session_recording: true,
    session_recording: {
      inlineStylesheet: true,
    },
    loaded: (posthogClient: PostHog) => {
      // eslint-disable-next-line testing-library/no-debugging-utils
      if (!IS_PRODUCTION) posthogClient.debug();

      // Merge PostHog anonymous ID with Segment anonymous ID
      aliasWithSegment(posthogClient);

      // Control the amount of users that have session recording enabled
      const shouldRecordSession = posthogClient.isFeatureEnabled(
        PosthogFlags.RecordWebSession,
      );
      if (shouldRecordSession) {
        posthogClient.startSessionRecording();
      }
    },
  });
}

const generateCanonicalUrl = (path: string): string | null => {
  const baseUrl = 'https://kyte.com';
  const patterns = [
    '/car-rental/',
    '/blog/',
    '/company/',
    '/rental/',
    '/road-trips/',
    '/',
    '/car-picker',
    '/download-app',
  ];

  for (const pattern of patterns) {
    if (path === pattern || path.startsWith(pattern)) {
      return `${baseUrl}${path}`;
    }
  }

  return null;
};

const App = ({
  Component,
  pageProps,
}: AppProps<{ data?: { [key: string]: any }; navBarData?: NavBarData }>) => {
  const router = useRouter();
  useInitSentry();
  useInitBranch();
  useInitIntercomTracking();

  const { enableAuth0QueryParam, setEnableAuth0QueryParam } =
    useEnableAuth0Store();

  // Only allow indexing on production and if the prop preventIndexing is either false or missing
  // @ts-ignore
  const preventIndexing = pageProps?.preventIndexing || false;
  const indexingContent =
    !IS_PRODUCTION || preventIndexing ? 'noindex, nofollow' : 'index, follow';

  const searchConfig =
    SEARCH_CONFIG_MAPPER[router.pathname] || SEARCH_CONFIG_MAPPER.default;
  const showUniqueRenters = typeof window !== 'undefined';

  const addParamToUrl = (url: string) => {
    if (url.includes('?')) {
      return `${url}&enable_auth0=true`;
    }
    return `${url}?enable_auth0=true`;
  };

  const canonicalUrl = generateCanonicalUrl(router.asPath);
  //TODO: maybe remove old navigation header
  const showNavigation =
    !router.pathname.includes('/welcome') &&
    !(router.pathname === '/') &&
    !(router.pathname === '/download-app') && // TODO: Remove or refactor when homepage_abovefold experiment complete
    !(router.pathname === '/car-picker') && // TODO: Refactor when carpickerv2 experiment complete
    !(router.pathname === '/partnerships') &&
    !(router.pathname === '/home-page-cms') &&
    !router.pathname.includes('/blog') &&
    !router.pathname.includes('/company') &&
    !router.pathname.includes('/checkout') &&
    !pageProps.navBarData;

  const showFooter = !(router.pathname === '/download-app');

  if (enableAuth0QueryParam && router.query.enable_auth0 !== 'true') {
    try {
      let url = window.location.href;
      url = addParamToUrl(url);
      window.location.href = url;
    } catch (error) {}
  }

  if (router.query.enable_auth0 === 'true' && !enableAuth0QueryParam) {
    setEnableAuth0QueryParam(true);
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
          key="viewport"
        />
        <meta
          property="og:title"
          content="Rental Cars, Delivered On Demand"
          key="og:title"
        />
        <meta property="og:url" content="https://kyte.com" key="og:url" />
        <meta name="url" content="https://kyte.com" key="url" />
        <meta
          property="og:image"
          content="https://drivekyte.s3-us-west-2.amazonaws.com/brand.png"
          key="og:image"
        />
        <meta
          property="og:description"
          content="Kyte delivers rental cars straight to your door. We deliver and pick up the vehicle. No lines, no paperwork, freedom to explore. Fully flexible. Book today!"
          key="og:description"
        />
        <meta
          name="description"
          content="Kyte delivers rental cars straight to your door. We deliver and pick up the vehicle. No lines, no paperwork, freedom to explore. Fully flexible. Book today!"
          key="description"
        />
        <meta property="og:site_name" content="Kyte" key="og:site_name" />
        <meta property="og:type" content="website" />
        <meta name="robots" content={indexingContent} key="robots-key" />
        <meta
          name="facebook-domain-verification"
          content="fo3bsnkcc8uqey3o4pzt1qc9rjzmwz"
        />
        {/* preload all 3party apps to reduce initial loading time and improve SEO performance for kyte.com */}
        {canonicalUrl && (
          <link rel="canonical" href={canonicalUrl} key="canonical" />
        )}
        <link rel="dns-prefetch" href="https://us.i.posthog.com/" />
        <link rel="preconnect" href="https://api-segment.drivekyte.com/" />
        <link rel="preconnect" href="https://cdn-segment.drivekyte.com/" />
        <link rel="dns-prefetch" href="https://bat.bing.com/" />
        <link rel="dns-prefetch" href="https://www.clarity.ms/" />
        <link rel="dns-prefetch" href="https://connect.facebook.net/" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://builder-assets.unbounce.com" />
        {/* adjust if we change the ubounce ad */}
        <link
          rel="dns-prefetch"
          href="https://8df169d5f2794b5ea8bc157847a27b9a.pages.ubembed.com/e4a94098-a928-4369-924a-70002db5ca5a/a.html?closedAt=0"
        />
      </Head>
      <Script
        strategy="afterInteractive"
        id="segment-snippet"
        dangerouslySetInnerHTML={{
          __html: renderSnippet(),
        }}
      />
      <Script
        strategy="lazyOnload"
        src="https://8df169d5f2794b5ea8bc157847a27b9a.js.ubembed.com"
        defer
      />
      <QueryClientProvider client={queryClient}>
        <HydrationZustandProvider>
          <KyteThemeProvider disableInjectCSS>
            <ThemeProvider theme={themes.kyte}>
              <ThemeBootstrap theme="kyte">
                <IntercomProvider>
                  <UserProvider>
                    <GoogleOAuthProvider
                      clientId={
                        process.env.NEXT_PUBLIC_GOOGLE_LOGIN_APP_ID || ''
                      }
                    >
                      <PostHogProvider client={posthog}>
                        <NotificationProvider>
                          {showNavigation && (
                            <Nav searchConfig={searchConfig} />
                          )}
                          <Content disableHeightConstrains={!showNavigation}>
                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                            <Component data={pageProps?.data} {...pageProps} />
                          </Content>
                          {showFooter && <Footer />}
                          {showUniqueRenters && <OtpModal />}
                          <div id="kyte-portal" />
                        </NotificationProvider>
                      </PostHogProvider>
                    </GoogleOAuthProvider>
                  </UserProvider>
                </IntercomProvider>
              </ThemeBootstrap>
            </ThemeProvider>
          </KyteThemeProvider>
        </HydrationZustandProvider>
      </QueryClientProvider>
    </>
  );
};

const Content = styled.section<{
  disableHeightConstrains: boolean;
}>`
  -webkit-font-smoothing: antialiased;
  ${({ disableHeightConstrains }) => css`
    min-height: ${disableHeightConstrains ? 0 : 'calc(100vh - 200px)'};
  `}}
`;

export default App;
